import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import('../views/Home.vue')
const About = () => import('../views/About.vue')
const Product = () => import('../views/Products.vue')
const Blog = () => import('../views/Blog.vue')
const BlogDetails = () => import('../views/BlogDetails.vue')
const Contact = () => import('../views/Contact.vue')
const Shop = () => import('../views/Shop.vue')
const ProductDetails = () => import('../views/ProductDetails.vue')
const Cart = () => import('../views/Cart.vue')
const Checkout = () => import('../views/Checkout.vue')
const Payment = () => import('../views/Payment.vue')
const CheckoutSuccess = () => import('../views/CheckoutSuccess.vue')
const DynamicPage = () => import('../views/DynamicPage.vue')
const MyOrder = () => import('../views/MyOrder.vue')
const ProductDesign = () => import('../views/ProductDesign.vue')
const ProductComparison = () => import('../views/ProductComparison.vue')
const Designers = () => import('../views/Designers.vue')
const CorporateSales = () => import('../views/CorporateSales.vue')
const CorporateSalesForm = () => import('../views/CorporateSalesForm.vue')
const WarrantyForm = () => import('../views/WarrantyFailureForm.vue')
const ExtraditionRequest = () => import('../views/ExtraditionRequest.vue')
const Exercises = () => import('../views/Exercises.vue')
const SearchResults = () => import('../views/search.vue')
const OrderSuccess = () => import('../views/OrderSucces.vue')
const Form = () => import('../views/Form.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Sağlıklı yaşam için ergonomik ve kaliteli sandalyeler. Ofis, spor salonu veya evde rahat bir deneyim için mükemmel seçimler.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Sağlıklı yaşam için ergonomik ve kaliteli sandalyeler. Ofis, spor salonu veya evde rahat bir deneyim için mükemmel seçimler. Farkı hissetmek için ürünlerimize göz atın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/',
    }
  },
  
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'Hakkımızda - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ergonomik sandalye tasarımlarımızla sağlıklı yaşamı destekliyoruz. Misyonumuz, ofis ve spor salonlarında konforu artırmak. Kalitemiz ve yenilikçi çözümlerimizle tanışın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Hakkımızda'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ergonomik sandalye tasarımlarımızla sağlıklı yaşamı destekliyoruz. Misyonumuz, ofis ve spor salonlarında konforu artırmak. Kalitemiz ve yenilikçi çözümlerimizle tanışın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/about',

    }
  },
  
  {
    path: '/products',
    name: 'Product',
    component: Product,
    meta: {
      title: 'Ürünler - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ergonomik ve şık sandalyelerle yaşam kalitenizi artırın. Ofis, spor salonu ve ev için tasarlanmış geniş ürün yelpazemizi keşfedin. Sağlıklı oturma deneyimini şimdi yaşayın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Ürünleri'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ergonomik ve şık sandalyelerle yaşam kalitenizi artırın. Ofis, spor salonu ve ev için tasarlanmış geniş ürün yelpazemizi keşfedin. Sağlıklı oturma deneyimini şimdi yaşayın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/products',

    }
  },
  
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta: {
      title: 'Blog - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye Blog: Sağlıklı yaşam, ergonomi ve fitness konularında en güncel ipuçları, tavsiyeler ve rehberler. Sağlıklı yaşam tarzınızı desteklemek için düzenli olarak güncellenen içeriklere göz atın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Blog Gönderileri'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye Blog: Sağlıklı yaşam, ergonomi ve fitness konularında en güncel ipuçları, tavsiyeler ve rehberler. Sağlıklı yaşam tarzınızı desteklemek için düzenli olarak güncellenen içeriklere göz atın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/blog',
    }
  },
  
  {
    path: '/blog/:slug',
    name: 'BlogDetails',
    component: BlogDetails,
    props: true,
    meta: {
      title: 'Blog - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Learn more about us on this page.'
        },
        {
          property: 'og:description',
          content: 'Learn more about us on this page.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Blog Gönderileri'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ]
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    props: true,
    meta: {
      title: 'İletişim - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye ile iletişime geçin. Sorularınız, önerileriniz veya işbirliği fırsatları için bize ulaşın. Müşteri destek ekibimiz size yardımcı olmaktan memnuniyet duyacaktır.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye ile iletişime geçin. Sorularınız, önerileriniz veya işbirliği fırsatları için bize ulaşın. Müşteri destek ekibimiz size yardımcı olmaktan memnuniyet duyacaktır.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye İletişim'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/contact',

    }
  },
  
 {
    path: '/spor-sandalyesi',
    name: 'Shop',
    component: Shop,
    props: true,
    meta: {
     title: 'Online Alışveriş - Ohup Fitness Türkiye',
    metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye ile ergonomik ve şık sandalyeler, online alışveriş kolaylığıyla. Ev, ofis ve spor salonu için mükemmel seçimler, hemen keşfedin ve satın alın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye ile ergonomik ve şık sandalyeler, online alışveriş kolaylığıyla. Ev, ofis ve spor salonu için mükemmel seçimler, hemen keşfedin ve satın alın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Online Alışveriş'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/shop',

    }
  },
  
  {
    path: '/products/:slug',
    name: 'ProductDetails',
    component: ProductDetails,
    props: true,
    meta: {
      title: 'Ürün Detayı - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ürün detaylarını öğrenin.'
        },
        {
          property: 'og:description',
          content: 'Ürün detaylarını öğrenin.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Ürünleri'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ]
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    props: true,
    meta: {
      title: 'Sepet - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Seçtiğiniz ergonomik ve şık sandalyelerle dolu sepetinizi inceleyin ve güvenli alışverişin tadını çıkarın. Konforlu bir yaşam için doğru ürünleri satın alın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Seçtiğiniz ergonomik ve şık sandalyelerle dolu sepetinizi inceleyin ve güvenli alışverişin tadını çıkarın. Konforlu bir yaşam için doğru ürünleri satın alın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Sepet Ürünleri'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/cart',

    }
  },
  
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    props: true,
   meta: {
      title: 'Ödeme - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Güvenli ve kolay ödeme süreci ile ergonomik sandalyelerinizi satın alın. Siparişinizi tamamlayın ve konforlu yaşam için bir adım daha atın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Güvenli ve kolay ödeme süreci ile ergonomik sandalyelerinizi satın alın. Siparişinizi tamamlayın ve konforlu yaşam için bir adım daha atın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Ödemeleri Yönet'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/checkout',

    }
  },
  
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    props: true,
    meta: {
      title: 'Ödeme - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ödeme işleminizi hızlı ve güvenli bir şekilde tamamlayın. Ergonomik sandalyelerinizi satın alarak konforlu yaşamın keyfini çıkarın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ödeme işleminizi hızlı ve güvenli bir şekilde tamamlayın. Ergonomik sandalyelerinizi satın alarak konforlu yaşamın keyfini çıkarın.'
        },
        {
          name: 'robots',
          content: 'nofollow, noindex'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/payment',

    }
  },
  
  {
    path: '/checkout-success/:token',
    name: 'CheckoutSuccess',
    component: CheckoutSuccess,
    props: true,
    meta: {
      title: 'Ödeme Başarılı - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ödemeniz başarıyla tamamlandı! Ergonomik sandalyeleriniz en kısa sürede adresinize gönderilecektir. Alışverişinizi bizimle yaptığınız için teşekkür ederiz.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ödemeniz başarıyla tamamlandı! Ergonomik sandalyeleriniz en kısa sürede adresinize gönderilecektir. Alışverişinizi bizimle yaptığınız için teşekkür ederiz.'
        },
        {
          name: 'robots',
          content: 'nofollow, noindex'
        }
      ]
    }
  },
  
  {
    path: '/policy/:pageName',
    name: 'DynamicPage',
    component: DynamicPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: 'Sayfa Bulunamadı - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Aradığınız sayfa bulunamadı. Ohup Fitness Türkiye: Sağlıklı yaşam ve ergonomik ürünler için ana sayfamıza geri dönün ve aradığınız bilgileri keşfedin.'
        },
        {
          property: 'og:description',
          content: 'Aradığınız sayfa bulunamadı. Ohup Fitness Türkiye: Sağlıklı yaşam ve ergonomik ürünler için ana sayfamıza geri dönün ve aradığınız bilgileri keşfedin.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ]
    }
  },
  {
    path: '/my-order',
    name: 'MyOrder',
    component: MyOrder,
    meta: {
      title: 'Siparişim Nerede? - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Siparişlerinizin durumu, teslimat ve ödeme bilgileri hakkında detaylı bilgi edinin. Sipariş süreçlerinizin her adımını takip edin ve güvenle alışveriş yapın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Siparişlerinizin durumu, teslimat ve ödeme bilgileri hakkında detaylı bilgi edinin. Sipariş süreçlerinizin her adımını takip edin ve güvenle alışveriş yapın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Siparişim Nerede?'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/my-order',

    }
  },
  {
    path: '/product-design',
    name: 'ProductDesign',
    component: ProductDesign,
    meta: {
      title: 'Ürün Tasarımı - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ergonomik sandalyelerimizin tasarım sürecini keşfedin. İnovasyon, konfor ve kaliteyi bir araya getirerek nasıl mükemmel ürünler geliştirdiğimizi öğrenin.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Ürün Tasarımı'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ergonomik sandalyelerimizin tasarım sürecini keşfedin. İnovasyon, konfor ve kaliteyi bir araya getirerek nasıl mükemmel ürünler geliştirdiğimizi öğrenin.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ]
    },
    canonical: 'https://www.ohupfitness.com.tr/product-design',

  },
  {
    path: '/product-comparison',
    name: 'ProductComparison',
    component: ProductComparison,
    meta: {
      title: 'Ürün Karşılaştırma - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ergonomik sandalyelerimizi karşılaştırarak ihtiyaçlarınıza en uygun ürünü bulun. Fiyat, özellikler ve tasarım açısından detaylı karşılaştırmalarla en doğru seçimi yapın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Ürünleri Karşılaştır'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ergonomik sandalyelerimizi karşılaştırarak ihtiyaçlarınıza en uygun ürünü bulun. Fiyat, özellikler ve tasarım açısından detaylı karşılaştırmalarla en doğru seçimi yapın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ]
    },
    canonical: 'https://www.ohupfitness.com.tr/product-comparison',

  },
  {
    path: '/designers',
    name: 'Designers',
    component: Designers,
    meta: {
      title: 'Tasarımcılar - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Ergonomik ve şık sandalyelerimizi tasarlayan yaratıcı ekibimizi tanıyın. Uzman tasarımcılarımızın vizyonu ve deneyimiyle üretilen benzersiz ürünler hakkında bilgi edinin.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Tasarımcıları'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Ergonomik ve şık sandalyelerimizi tasarlayan yaratıcı ekibimizi tanıyın. Uzman tasarımcılarımızın vizyonu ve deneyimiyle üretilen benzersiz ürünler hakkında bilgi edinin.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/designers',

    }
  },
  {
    path: '/corporate-sales',
    name: 'CorporateSales',
    component: CorporateSales,
    meta: {
      title: 'Kurumsal Satış - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Şirketiniz için ergonomik ve şık sandalyeler. Kurumsal satış çözümlerimizle iş yerinizin konforunu artırın. Toplu alımlar için özel tekliflerden faydalanın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Kurumsal Satış'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Şirketiniz için ergonomik ve şık sandalyeler. Kurumsal satış çözümlerimizle iş yerinizin konforunu artırın. Toplu alımlar için özel tekliflerden faydalanın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ]
    },
    canonical: 'https://www.ohupfitness.com.tr/corporate-sales',

  },
  {
    path: '/corporate-sales-form',
    name: 'CorporateSalesForm',
    component: CorporateSalesForm,
    meta: {
      title: 'Kurumsal Satış Başvuru - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Kurumsal satış başvuru formumuzu doldurarak ergonomik ve şık sandalyelerimiz için özel teklif alın. Şirketinize en uygun çözümleri sunuyoruz.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Başvuru Formu'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Kurumsal satış başvuru formumuzu doldurarak ergonomik ve şık sandalyelerimiz için özel teklif alın. Şirketinize en uygun çözümleri sunuyoruz.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/corporate-sales-form',

    }
  },
  {
    path: '/warranty-failure-form',
    name: 'WarrantyForm',
    component: WarrantyForm,
    meta: {
      title: 'Garanti Arıza Formu - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Garanti ve arıza talepleriniz için formumuzu doldurarak destek alın. Ürünlerinizin garantisi kapsamında hızlı ve etkili çözümler sunuyoruz.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Garanti Arıza Formu'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Garanti ve arıza talepleriniz için formumuzu doldurarak destek alın. Ürünlerinizin garantisi kapsamında hızlı ve etkili çözümler sunuyoruz.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/warranty-failure-form',

    }
  },
  {
    path: '/extradition-request',
    name: 'ExtraditionRequest',
    component: ExtraditionRequest,
    meta: {
      title: 'İade Talebi Oluştur - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Satın aldığınız ürünler için iade talebi oluşturun. İade süreciyle ilgili adımları takip edin ve kolayca iade işleminizi başlatın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye İade Talebi Oluştur'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Satın aldığınız ürünler için iade talebi oluşturun. İade süreciyle ilgili adımları takip edin ve kolayca iade işleminizi başlatın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/extradition-request',

    }
  },
  {
    path: '/exercises',
    name: 'Exercises',
    component: Exercises,
    meta: {
      title: 'Egzersizler - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          property: 'og:title',
          content: 'Ohup Fitness Türkiye Egzersizler'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          name: 'robots',
          content: 'follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large'
        }
      ],
      canonical: 'https://www.ohupfitness.com.tr/exercises',

    }
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults,
    meta: {
      title: 'Arama Sonucu - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          name: 'robots',
          content: 'nofollow, noindex'
        }
      ]
    }
  },

  {
    path: '/order-succes/:orderId',
    name: 'OrderSuccess',
    component: OrderSuccess,
    props:true,
    meta: {
      title: 'Ödeme Sonucu - Ohup Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          name: 'robots',
          content: 'nofollow, noindex'
        }
      ]
    }
  },
  {
    path: '/form',
    name: 'Form',
    component: Form,
    props:true,
    meta: {
      title: 'Etkinlik Katılım Formu - Ohup Fitness Türkiye',
      metaTags: [
        {
          name: 'description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          property: 'og:description',
          content: 'Ohup Fitness Türkiye: Farklı egzersizler için hazırlanan videolarımızla sağlıklı ve formda kalın. Profesyonel eğitmenler tarafından hazırlanan egzersiz videolarını keşfedin ve evde antrenman yapmanın keyfini çıkarın.'
        },
        {
          name: 'robots',
          content: 'nofollow, noindex'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));

  if (to.meta.metaTags) {
    to.meta.metaTags.forEach(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      document.head.appendChild(tag);
    });
  }

  next();
});

export default router
