<script setup>
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { useHead } from '@vueuse/head';
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import CookieConsent from './components/CookieConsent.vue'
const route = useRoute();

const noHeaderFooterRoutes = ['/checkout' ];
const NoHeader = ['/form'];

const normalizeUrl = (url) => url.replace(/\/$/, '');

watch(
  () => normalizeUrl(route.meta.canonical || `${window.location.origin}${route.fullPath}`),
  (canonical) => {
    useHead({
      link: [
        {
          rel: 'canonical',
          href: canonical,
        },
      ],
    });
  },
  { immediate: true }
);
</script>

<template>
  <div id="app-container">
    <Header v-if="!noHeaderFooterRoutes.includes(route.path) && !NoHeader.includes(route.path)" />
    <main>
      <router-view />
    </main>
    <Footer v-if="!noHeaderFooterRoutes.includes(route.path)" />
    <CookieConsent />
  </div>
</template>

<style scoped>



</style>
