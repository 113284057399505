<template>
  <div v-if="!accepted" class="cookie-consent">
    <div class="cookie-content">
      <p>
        Bu site, deneyiminizi geliştirmek için çerezler kullanır. Bu siteyi kullanmaya devam ederek, çerez kullanımımızı kabul etmiş olursunuz.
      </p>
      <div class="cookie-actions">
        <button class="btn accept" @click="acceptCookies('all')">Hepsini Kabul Et</button>
        <button class="btn select" @click="acceptCookies('selection')">Seçimi Kabul Et</button>
        <button class="btn deny" @click="acceptCookies('deny')">Reddet</button>
      </div>
    </div>
    <div class="cookie-categories">
      <div class="category">
        <span>Gerekli</span>
        <label class="switch">
          <input type="checkbox" checked disabled>
          <span class="slider round"></span>
        </label>
      </div>
      <div class="category">
        <span>Tercihler</span>
        <label class="switch">
          <input type="checkbox" v-model="preferencesAccepted">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="category">
        <span>İstatistikler</span>
        <label class="switch">
          <input type="checkbox" v-model="statisticsAccepted">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="category">
        <span>Pazarlama</span>
        <label class="switch">
          <input type="checkbox" v-model="marketingAccepted">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>

  
  <script>
  export default {
    data() {
      return {
        accepted: false,
        preferencesAccepted: true,
        statisticsAccepted: true,
        marketingAccepted: true,
        gTag: '',
      };
    },
    methods: {
      acceptCookies(type) {
        if (type === 'all') {
          this.preferencesAccepted = true;
          this.statisticsAccepted = true;
          this.marketingAccepted = true;
        } else if (type === 'deny') {
          this.preferencesAccepted = false;
          this.statisticsAccepted = false;
          this.marketingAccepted = false;
        }
        localStorage.setItem('cookiesAccepted', true);
        localStorage.setItem('preferencesAccepted', this.preferencesAccepted);
        localStorage.setItem('statisticsAccepted', this.statisticsAccepted);
        localStorage.setItem('marketingAccepted', this.marketingAccepted);
        this.accepted = true;
        this.loadAnalytics();
      },
      checkAcceptance() {
    this.accepted = localStorage.getItem('cookiesAccepted') === 'true';
    
    // Check if cookies were accepted for preferences, statistics, and marketing
    if (this.accepted) {
      this.preferencesAccepted = localStorage.getItem('preferencesAccepted') === 'true';
      this.statisticsAccepted = localStorage.getItem('statisticsAccepted') === 'true';
      this.marketingAccepted = localStorage.getItem('marketingAccepted') === 'true';

      // Call loadAnalytics if statistics or marketing cookies were accepted
      if (this.statisticsAccepted || this.marketingAccepted) {
        this.fetchGTag(); // Ensure the tag is fetched and analytics is loaded
      }
    }
  },
  fetchGTag() {
    // Fetch the Google Analytics tag if needed
    fetch('https://www.ohupfitness.com.tr/inc/analytics.php')
      .then(response => response.json())
      .then(data => {
        this.gTag = data.G_TAG || 'G-0FPBY2ML1H'; // Fallback to hardcoded tag if not fetched
        this.loadAnalytics(); // Now that the tag is available, load analytics
      });
  },
  loadAnalytics() {
  if (!this.gTag || (!this.statisticsAccepted && !this.marketingAccepted)) return; // Only load if cookies are accepted for stats or marketing

  // Check if the gtag script has already been added
  if (!document.getElementById('ga-script')) {
    const script = document.createElement('script');
    script.id = 'ga-script';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gTag || 'G-0FPBY2ML1H'}`;
    document.head.appendChild(script);

    // Initialize gtag after loading the script
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', this.gTag || 'G-0FPBY2ML1H', {
        'cookie_domain': 'ohupfitness.com.tr'
        });
    };
  }
}

    },
    mounted() {
      this.checkAcceptance(); 
    },
  };
  </script>
  
  <style scoped>
  /* Modernized Cookie Consent */
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 20px;
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  animation: slideUp 0.5s ease-in-out;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: "League Spartan", sans-serif;
}

/* Cookie Content */
.cookie-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  text-align: center;
  margin-bottom: 20px;
}

.cookie-content p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #f8f8f8;
}

/* Cookie Actions */
.cookie-actions {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 600px;
}

.cookie-actions .btn {
  padding: 12px 25px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cookie-actions .btn.accept {
  background-color: #000;
  color: #fff;
}

.cookie-actions .btn.accept:hover {
  background-color: #292929;
}

.cookie-actions .btn.select {
  background-color: #ffa100;
  color: #fff;
}

.cookie-actions .btn.select:hover {
  background-color: #ffb85b;
}

.cookie-actions .btn.deny {
  background-color: #000;
  color: #fff;
}

.cookie-actions .btn.deny:hover {
  background-color: #e53935;
}

/* Cookie Categories */
.cookie-categories {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 600px;
  margin-top: 15px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.category {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
}

.category span {
  margin-right: 10px;
  font-size: 14px;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 50%;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffa100;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .cookie-actions {
    flex-direction: column;
    width: 100%;
  }

  .cookie-actions .btn {
    margin-bottom: 10px;
  }

  .cookie-categories {
    flex-direction: column;
    width: 100%;
  }

  .category {
    width: 100%;
    justify-content: space-between;
  }
}

  </style>