import { createApp } from 'vue';
import './style.css';
import router from './router';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import VueLazyload from 'vue-lazyload';
const app = createApp(App);
const head = createHead();
app.use(VueLazyload, {
  loading: '',
  error: '/assets/icons/error.png'
});

app.use(router);
app.use(head);

app.mount('#app');
